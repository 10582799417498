<template>
  <tr :style="{ background: props.item.status_id == 3 ? 'lightgray' : '' }">
    <td class="justify-left">
      <div style="min-width: 80px !important;">
        <v-btn
          @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
          v-prms="{'id': '82b8e3b9', mode: 'disabled'}" 
          slot="active" icon class="mx-0 wrapper"
          :disabled="props.item.status_id == 3"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          @click="$WiEditDialog({wiConfig: 'finance-recurrence-remove', onSubmit: config.refresh, data: { id: props.item.id }})" 
          v-prms="{'id': 'e90e45c8', mode: 'disabled'}" 
          slot="active" icon class="mx-0 wrapper"
          :disabled="props.item.status_id == 3"
        >
          <v-icon color="red">power_settings_new</v-icon>
        </v-btn>
        <v-btn
          :to="{name: 'finance-list', params: {page: 'finance-in-list'}, query: {'finance_recurrence_id': props.item.id}}" 
          v-prms="{'id': 'b8330e5f', mode: 'disabled'}" 
          slot="active" icon class="mx-0 wrapper"
        >
          <v-icon>list</v-icon>
        </v-btn>
      </div>
    </td>
    <td class="justify-left">
      {{ props.item.id }}
    </td>   
    <td class="justify-center" :style="{color: props.item.in_out == 1 ? '#4A925F' : '#C62828'}">
      <div v-if="props.item.in_out == 1" style="text-align: center;">
        <v-icon>get_app</v-icon> <br>
        <span>Receita</span>
      </div>
      <div v-if="props.item.in_out == 2" style="text-align: center;">
        <v-icon>publish</v-icon> <br>
        <span>Despesa</span>
      </div>
    </td>
    <td class="justify-left">
      <strong>{{ props.item.date_start | moment('DD/MM/YYYY') }}</strong><br>
    </td>
    <td class="justify-left">
      <strong style="font-size: 16px;">
        {{ props.item.amount_total | money }}
      </strong>
    </td>
    <td class="justify-center">
      <div v-if="props.item.frequency_id == 1" class="finance_frequency finance_frequency--month">
        <v-icon color="white">calendar_month</v-icon>
        <span>Mensal</span>
      </div>
      <div v-else-if="props.item.frequency_id == 5" class="finance_frequency finance_frequency--yahr">
        <v-icon color="white">event</v-icon>
        <span>Anual</span>
      </div>
    </td>
    <td class="justify-left">
      <span> {{ props.item.description || ' - - - ' }} </span>
      <strong v-if="props.item.person && props.item.person.name">
        <br>{{ props.item.person.name }}
      </strong>
    </td>
    <td class="justify-center" style="text-align: center;">
      <div v-if="props.item.finance_form_payment_id && props.item.form_payment">
        <v-icon :color="props.item.form_payment.color">{{props.item.form_payment.icon}}</v-icon> <br>
        <span>{{props.item.form_payment.name}}</span>
      </div>
      <div v-else>Indefinido</div>
    </td>
    <td class="justify-left">
      <span v-if="props.item.account">{{ props.item.account.name }}</span>
      <span v-else>- - -</span>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'Finances',
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
  .finance_frequency {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
    max-width: 90px;
    border-radius: 5px;
    background: rgb(0, 195, 255);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .finance_frequency--month {
    background: rgb(0, 195, 255);
  }
  .finance_frequency--yahr {
    background: rgb(0, 85, 155);;
  }
  .canceled {
    color: grey;
    background: #eceff1;
  }
  .unpaid {
    color: #ff6d00;
    background: #f0f4c3;
  }
  .expired {
    color: #F44336;
    background: #ffe7e5;
  }
  .paid {
    color: green;
    background: #b9f6ca;
  }
  .parcel {
    color: white;
    background: #000;
    min-width: 44px !important;
    width: 44px !important;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .finances__status-days {
    font-size: 12px;
    min-width: 70px;
  }
  .finances__row--negatived {
    background: black;
    color: white !important;
  }
  .finances__row--negatived .v-icon {
    color: white !important;
  }
</style>